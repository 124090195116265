import React, { useState, useEffect } from 'react';
import { Button, message, Input } from 'antd';
// apis
import { userSubmit } from '../../../apis/About';
// import asset from '../../asset/aboutyili.2abff892.png';
// import xiazai from '../../asset/xiazai.png';
// import Emel from '../../asset/Emel.png';
// import position from '../../asset/position.png';
import { Helmet } from 'react-helmet';
import AboutCompany from './components/aboutCompany.js'
import DevelopingCause from './components/developingCause.js'
import CorporateCulture from './components/corporateCulture.js'
import ContactUs from './components/contactUs.js'
import './page.css'
let pageTimer = '' //停留时间

function About(props) {
  useEffect(() => {
    // 高德地图初始化
    window.onload = () => {
      if (window.AMap) {
        const AMap = window.AMap;
        const point = new AMap.LngLat(104.068105,30.492512);
        const map = new AMap.Map("yili-map",{
          center: point,
          zoom: 16,
        });
        // 创建标注
        const marker = new AMap.Marker({
          position: point
        });
        // 将标注添加到地图中
        map.add(marker);
        // 自定义信息窗体内容的HTML和样式
        const customContent = `
          <div style="background-color: #FFFFFF; padding: 10px; border-radius: 5px; width: 240px; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);">
            <h3 style="margin: 0; font-size: 16px; color: #333;">壹立科技</h3>
            <p style="margin: 5px 0 0 0; font-size: 14px; color: #666;">地址：成都市双流区天府新区天府大道三利广场4号楼</p>
          </div>
        `;
        // 信息窗体配置
        var opts = {
          isCustom: true, // 使用自定义窗体
          content: customContent, // 信息窗体内容
          closeWhenClickMap: true, //点击关闭信息窗体
          offset: new AMap.Pixel(0, -30), //偏移值
        };
        // 创建信息窗口对象
        const infoWindow = new AMap.InfoWindow(opts);
        // 监听标注点击事件
        marker.on("click", function () {
          infoWindow.open(map, marker.getPosition()); // 点击时显示信息窗体
        });
      }
    }
    // 获取进入页面的时间
    if (pageTimer === '') {
      pageTimer = parseInt(new Date().getTime() / 1000);
      console.log(pageTimer, 'pageTimer')
    }
    props.onVisitClick({
      type: 1,
      domain: 'www.qolai.cn',
      page: 'about'
    }, 0)
  
    // 这个返回的函数，会在该组件卸载时来执行
    // 因此，可以去执行一些清理操作，比如，解绑 window 的事件、清理定时器 等
    return () => {
      // 离开时间减去进入时间
      const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
      console.log(tempTimer, 'tempTimer')
      // 清空时间戳
      pageTimer = '';
      if (tempTimer > 0) {
        props.onVisitClick({
          type: 2,
          domain: 'www.qolai.cn',
          page: 'about',
          time: tempTimer
        }, 0)
      }
    }
  }, []);
  // 姓名
  const [name, setName] = useState('');
  // 电话
  const [phone, setPhone] = useState('');
  // 邮箱
  const [email, setEmail] = useState('');
  // 建议
  const [proposal, setProposal] = useState('');

  const mysubmit = async () => {
    const phoneExp = /^1[3456789]\d{9}$/;
    const emailExp = /^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$/;

    if (name === '') {
      message.error('请填写姓名！')
      return
    }

    if (phone === '' || !phoneExp.test(phone)) {
      message.error('请填写正确的电话号码！')
      return
    }

    if (email === '' || !emailExp.test(email)) {
      message.error('请填写正确的邮箱！')
      return
    }

    if (proposal === '') {
      message.error('请填写建议！')
      return
    }

    const result = await userSubmit(name, email, phone, proposal);
    if (result.code === 200) message.success('提交成功！')
  }

  return (
    <div>
      <Helmet>
        <title>趣来达后台管理系统。</title>
        <meta name="Description" content="趣来达后台管理系统，聚合多元化服务，打造本地智慧生活服务，满足用户、商家、骑手、运营者多角色全方位使用，提供全面升级的跑腿配送服务体验。" />
        <meta name="Keywords" content="同城跑腿软件,配送管理软件,城市配送系统,跑腿行业,跑腿公司-趣来达" />
      </Helmet>
      {/**banner**/}
      <div className="about-banner d-flex justify-content-center align-items-center ">
        <div style={{width:'1290px'}}>
          <div className="animated fadeInLeft" style={{marginLeft: '27px',marginBottom:'120px'}}>
            <h1 style={{marginBottom:'19\0px'}} className="about-title">壹立科技，让科技更有价值</h1>
            <span className="about-dec">截止现在</span>
          </div>
          <div className="d-flex animated fadeInRight">
            <div className="d-flex flex-column" style={{padding: '0px 30px'}}>
              <span className="about-right-title text-center">服务团队</span>
              <span className="about-right-num text-center">4000+</span>
              <span className="about-right-dec text-center">Enterprise</span>
            </div>
            <div className="d-flex flex-column" style={{padding: '0px 30px',borderRight: '1px solid #B6B3D5', borderLeft: '1px solid #B6B3D5'}}>
              <span className="about-right-title text-center">服务用户</span>
              <span className="about-right-num text-center">1000000+</span>
              <span className="about-right-dec text-center">Client</span>
            </div>
            <div className="d-flex flex-column" style={{padding: '0px 30px'}}>
              <span className="about-right-title text-center">入驻商户</span>
              <span className="about-right-num text-center">30000+</span>
              <span className="about-right-dec text-center">traffic</span>
            </div>
          </div>
        </div>
      </div>
      
      {/**板块二**/}
      <div>
        <AboutCompany />
        <DevelopingCause />
        <CorporateCulture />
        <ContactUs />
      </div>
      {/* <div className="about-plate-2">
        <div className="d-flex justify-content-center align-items-center">
          <div className="" style={{marginRight: '128px'}}>
            <h4 className="about-plate-2-title">壹立科技</h4> <span className = "d-block"
            style = { { width: '50px', height: '2px', background: '#F5AD01' } } > </span>
            <p className="about-plate-2-text-main fw-600">
            <span>成都壹立科技有限公司，秉持“以壹为始，立壹之上”的企业发展理念，从壹出发，不忘初心，潜心打造产品、打造值得用户信赖的产品，提供体系完善的品质服务，连续多年稳健运营，久经市场重重考验，业务丰富，为全行业企业信息化做持久贡献，成熟的运营模式让企业拥有全球多个城市的合作商，合同到期合伙人续约率高，深受合作商认同。</span>
              <br />
              <span className="d-inline-block" style={{marginTop: '20px'}}>成都壹立科技有限公司，创立于天府蓉城“高端产业新城”——高新区，公司专注于互联网技术研发，是一家集软件定制、开发、服务于一体的个性化软件系统研发企业。</span>
              <br />
              <span className="d-inline-block" style={{marginTop: '20px'}}>目前，壹立科技旗下有包括壹脉销客AI智能名片、趣来达跑腿系统、趣来达外卖系统、趣来达校园跑腿系统、趣来达校园外卖系统等多个公司自主品牌、自主产权、自主研发设计的软件系统，公司始终着眼于用户的需求，以帮助企业走上“互联网+”之路、实现企业运营管理的规范化、科学化、现代化为已任，把开发出符合客户需求的软件系统当做自己的使命，研发出一系列软件，满足不同企业的需求。+”之路、实现企业运营管理的规范化、科学化、现代化为已任，把开发出符合客户需求的软件系统当做自己的使命，研发出一系列软件，满足不同企业的需求。</span>
              <br />
              <span className="d-inline-block" style={{marginTop: '20px'}}> 公司集开发、运营、销售、服务于一体，拥有一支开发经验丰富的精英技术团队，服务超过4000+个合作商，在研发运营公司自营成熟产品，提供给客户系统软件技术服务的同时，也为客户提供移动互联网产品定制开发服务，包括微信公众号、小程序、APP等定制开发。</span>
              <br />
              <span className="d-inline-block" style={{marginTop: '20px'}}>丰富的开发经验，在技术上精益求精，为传统企业客户带来变量，为互联网创业者实现商业梦想。</span>
            </p>
          </div> */}
          {/**右侧图片**/}
          {/* <div style={{marginTop: 122}}>
            <img alt="img" src={asset} style={{height: '300px',width: '533px'}}/>
          </div>
        </div>
      </div> */}
      {/* <div style={{marginTop: '100px', paddingTop: '100px'}} className="d-flex justify-content-center">
        <div style={{marginRight: '144px'}}>
          <div id="yili-map">
          </div>
        </div>
        <div className="about-form">
          <h4 className="m-0" style={{color: '#333333', fontSize: '36px', lineHeight: '58px'}}>联系我们</h4>
          <span className="d-inline-block" style={{width: '50px', height: '2px', background: '#F5AD01'}}></span>
          <div style={{marginTop: '50px'}} className="d-flex align-items-center">
            <div className="flex-grow-1">
              <label style={{fontSize: '16px', color: '#35373D', marginBottom: '15px'}} className="d-block">姓名</label>
              <Input maxLength="6" className="form-input w-100" placeholder="请输入您的姓名" value={name} allowClear onChange={e => {setName(e.currentTarget.value)}}/>
            </div>
            <div className="flex-grow-1" style={{paddingLeft: '17px'}}>
              <label style={{fontSize: '16px', color: '#35373D', marginBottom: '15px'}} className="d-block">电话</label>
              <Input maxLength="11" className="form-input w-100" placeholder="请输入您的电话" value={phone} onChange={e => {setPhone(e.currentTarget.value)}} allowClear/>
            </div>
          </div>
          <div style={{marginTop: '30px'}} className="d-flex align-items-center">
            <div className="flex-grow-1">
              <label style={{fontSize: '16px', color: '#35373D', marginBottom: '15px'}} className="d-block">邮箱</label>
              <Input className="form-input w-100" placeholder="请输入您的邮箱" value={email} onChange={e => {setEmail(e.currentTarget.value)}} allowClear/>
            </div>
          </div>
          <div style={{marginTop: '30px'}} className="d-flex align-items-center">
            <div className="flex-grow-1">
              <label style={{fontSize: '16px', color: '#35373D', marginBottom: '15px'}} className="d-block">需求或建议</label>
              <Input.TextArea style={{height: 124}} className="form-input w-100" value={proposal} onChange={e => {setProposal(e.currentTarget.value)}} placeholder="请输入您的需求或建议"/>
            </div>
          </div>
          <Button onClick={() => {
            props.onVisitClick({
              type: 3,
              domain: 'www.qolai.cn',
              page: 'about',
              button: 'sendMessage'
            },0);
            mysubmit()}} style={{marginTop: '30px'}} className="form-btn">发送信息</Button>
        </div>
      </div> */}
      {/**三张卡片**/}
      {/* <div style={{marginTop: 124, paddingBottom: 124}} className="d-flex justify-content-center align-items-center">
        <div className="about-card d-flex justify-content-center align-items-center">
          <img alt="img" src={xiazai} style={{width: 44,height: 44}}/>
          <div style={{marginLeft: 30}}>
            <span className="d-block">028-65731131</span>
            <span>17721872191   18081916514</span>
          </div>
        </div>
        <div className="about-card d-flex justify-content-center align-items-center" style={{margin: '0 20px'}}>
          <img alt="img" src={Emel} style={{width: 44,height: 44}}/>
          <div style={{marginLeft: 30}}>
            <span className="d-block">邮箱地址</span>
            <span>yiliit@163.com</span>
          </div>
        </div>
        <div className="about-card d-flex justify-content-center align-items-center">
          <img alt="img" src={position} style={{width: 44,height: 44}}/>
          <div style={{marginLeft: 30}}>
            <span className="d-block">成都高新区新裕路501号博雅城市广场c栋11层</span>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default About;